import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Lock, KeyRound } from 'lucide-react';
import { usePasswordProtection } from '../../hooks/usePasswordProtection';

const PasswordButton = () => {
  const [showModal, setShowModal] = useState(false);
  const {
    password,
    setPassword,
    error,
    showError,
    validatePassword
  } = usePasswordProtection();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    validatePassword();
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      validatePassword();
    }
  };

  return (
    <>
      <motion.button
        onClick={() => setShowModal(true)}
        className="bg-white/10 backdrop-blur-lg px-6 py-3 rounded-lg shadow-lg hover:bg-white/20 transition-colors duration-300 text-white flex items-center space-x-2"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Lock size={20} />
        <span>Acceder</span>
      </motion.button>

      <AnimatePresence>
        {showModal && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50"
              onClick={() => setShowModal(false)}
            />
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white/10 backdrop-blur-lg p-8 rounded-lg shadow-2xl max-w-md w-full mx-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="text-center mb-6">
                <Lock className="w-12 h-12 mx-auto mb-4 text-white" />
                <h2 className="text-2xl font-bold text-white mb-2">Acceso Protegido</h2>
                <p className="text-gray-300">Por favor, introduce la contraseña para acceder</p>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="relative">
                  <KeyRound className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    className="w-full pl-10 pr-4 py-2 bg-white/20 border border-white/30 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white/50"
                    placeholder="Contraseña"
                    autoFocus
                  />
                </div>

                <AnimatePresence>
                  {showError && (
                    <motion.p
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0 }}
                      className="text-red-400 text-sm text-center"
                    >
                      {error}
                    </motion.p>
                  )}
                </AnimatePresence>

                <motion.button
                  type="submit"
                  className="w-full bg-white/20 hover:bg-white/30 text-white py-2 rounded-lg transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-white/50"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Acceder
                </motion.button>
              </form>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default PasswordButton;