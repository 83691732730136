import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ServiceLayout from '../components/services/ServiceLayout';
import SalonService from '../components/services/SalonService';
import BanoService from '../components/services/BanoService';
import JardinService from '../components/services/JardinService';
import CocinaService from '../components/services/CocinaService';
import ServicesOverview from '../components/services/ServicesOverview';

const Services = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <main className="pt-16">
      <Routes>
        <Route index element={<ServicesOverview />} />
        <Route path="salon" element={<ServiceLayout><SalonService /></ServiceLayout>} />
        <Route path="bano" element={<ServiceLayout><BanoService /></ServiceLayout>} />
        <Route path="jardin" element={<ServiceLayout><JardinService /></ServiceLayout>} />
        <Route path="cocina" element={<ServiceLayout><CocinaService /></ServiceLayout>} />
      </Routes>
    </main>
  );
};

export default Services;