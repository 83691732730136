import React from 'react';
import Hero from '../components/Hero';
import MissionVision from '../components/MissionVision';
import Partners from '../components/Partners';

const Home = () => {
  return (
    <main>
      <Hero />
      <MissionVision />
      <Partners />
    </main>
  );
};

export default Home;