import { MutableRefObject } from 'react';

export const setupVideoElement = (video: HTMLVideoElement) => {
  video.muted = true;
  video.playsInline = true;
  video.loop = true;
  video.setAttribute('webkit-playsinline', 'true');
  video.preload = 'auto';
};

export const playVideo = async (video: HTMLVideoElement): Promise<void> => {
  try {
    if (video.paused) {
      await video.play();
    }
  } catch (error) {
    console.warn('Video autoplay was prevented:', error);
  }
};

export const cleanupVideo = (video: HTMLVideoElement) => {
  video.pause();
  video.currentTime = 0;
  video.src = '';
  video.load();
};

export const preloadVideo = (src: string): Promise<void> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'auto';
    video.src = src;
    video.load();
    video.onloadeddata = () => resolve();
  });
};

export const preloadVideos = async (sources: string[]): Promise<void> => {
  await Promise.all(sources.map(preloadVideo));
};