import React from 'react';
import { motion } from 'framer-motion';
import { ExternalLink } from 'lucide-react';

const partners = [
  {
    name: 'Muare Home',
    logo: 'https://cdn.shopify.com/s/files/1/0843/1488/2375/files/muare_marca_69399b8e-05d7-49b4-b6f8-7ca5c0f9b049.png?v=1732111702',
    description: 'Textil Cocina',
    url: 'https://muarehome.com/'
  },
  {
    name: 'Terracota Casa',
    logo: 'https://cdn.shopify.com/s/files/1/0843/1488/2375/files/TERRACOTA_eb28f9b2-1073-441a-9f14-b37db241cc93.png?v=1732111733',
    description: 'Del Mediterráneo',
    url: 'https://terracotacasa.com/'
  },
  {
    name: 'SG Partners',
    logo: 'https://cdn.shopify.com/s/files/1/0843/1488/2375/files/sgpartners_logo_3e88b42c-9582-47f3-95e9-93410add60cc.jpg?v=1732111750',
    description: 'Trend Fabrics',
    url: 'https://www.sgpartners.es/'
  }
];

const Partners = () => {
  return (
    <section className="py-20 bg-white dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Nuestros Partners
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Colaboramos con las mejores marcas del sector
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {partners.map((partner, index) => (
            <motion.div
              key={partner.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <a
                href={partner.url}
                target="_blank"
                rel="noopener noreferrer"
                className="group block"
              >
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="bg-white dark:bg-gray-800 rounded-lg p-8 shadow-lg transition-all duration-300 hover:shadow-xl relative"
                >
                  <div className="aspect-w-16 aspect-h-9 mb-6">
                    <img
                      src={partner.logo}
                      alt={partner.name}
                      className="object-contain w-full h-32 filter dark:brightness-90 dark:contrast-125"
                    />
                  </div>
                  <div className="text-center">
                    <p className="text-lg font-medium text-gray-900 dark:text-white mb-2 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 transition-colors">
                      {partner.description}
                    </p>
                    <div className="flex items-center justify-center text-gray-500 dark:text-gray-400 group-hover:text-indigo-600 dark:group-hover:text-indigo-400 transition-colors">
                      <ExternalLink size={16} className="mr-1" />
                      <span className="text-sm">Visitar sitio</span>
                    </div>
                  </div>
                </motion.div>
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;