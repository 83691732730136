import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import AppRoutes from './routes/AppRoutes';
import SEO from './components/SEO';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <SEO />
        <AppRoutes />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;