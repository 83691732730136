import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { label: t('nav.home'), path: '/' },
    {
      label: t('nav.services'),
      path: '/servicios',
      children: [
        { label: t('services.salon'), path: '/servicios/salon' },
        { label: t('services.bano'), path: '/servicios/bano' },
        { label: t('services.jardin'), path: '/servicios/jardin' },
        { label: t('services.cocina'), path: '/servicios/cocina' },
      ],
    },
    { label: t('nav.project'), path: '/proyecto' },
    { label: t('nav.faq'), path: '/faq' },
    { label: t('nav.contact'), path: '/contacto' },
  ];

  const isHomePage = location.pathname === '/';

  const handleNavigation = (path: string) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsOpen(false);
  };

  const shouldShowMenu = !isHomePage || isScrolled;

  return (
    <nav className="fixed w-full z-50 bg-white/95 dark:bg-black/95 shadow-lg backdrop-blur-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 md:h-20">
          <motion.div
            initial={false}
            animate={{
              x: isHomePage && !isScrolled ? '-50%' : '0%',
              left: isHomePage && !isScrolled ? '50%' : '0%',
              scale: isHomePage && !isScrolled ? 1.2 : 1
            }}
            transition={{ duration: 0.6, ease: "easeInOut" }}
            className="relative flex-shrink-0"
          >
            <Link to="/" onClick={() => handleNavigation('/')}>
              <img
                src="https://cdn.shopify.com/s/files/1/0692/1132/4730/files/LOGO_GRAMAGE_TEXTIL.png?v=1733402877"
                alt="GramageTextil"
                className={`h-6 md:h-10 transition-all duration-500 ${isDarkMode ? 'brightness-0 invert' : ''}`}
              />
            </Link>
          </motion.div>

          <motion.div
            initial={false}
            animate={{
              opacity: shouldShowMenu ? 1 : 0,
              visibility: shouldShowMenu ? 'visible' : 'hidden'
            }}
            transition={{ duration: 0.3 }}
            className="hidden md:flex items-center space-x-8"
          >
            {navItems.map((item) => (
              <div key={item.path} className="relative group">
                <Link
                  to={item.path}
                  onClick={() => handleNavigation(item.path)}
                  className={`px-3 py-2 text-sm font-medium ${
                    location.pathname === item.path
                      ? 'text-black dark:text-white'
                      : 'text-gray-600 dark:text-gray-300'
                  } hover:text-black dark:hover:text-white transition-colors`}
                >
                  {item.label}
                </Link>
                {item.children && (
                  <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                    <div className="py-1">
                      {item.children.map((child) => (
                        <Link
                          key={child.path}
                          to={child.path}
                          onClick={() => handleNavigation(child.path)}
                          className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100/90 dark:hover:bg-gray-700/90"
                        >
                          {child.label}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </motion.div>

          <motion.div
            initial={false}
            animate={{
              opacity: shouldShowMenu ? 1 : 0,
              visibility: shouldShowMenu ? 'visible' : 'hidden'
            }}
            transition={{ duration: 0.3 }}
            className="md:hidden"
          >
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 rounded-md text-gray-600 dark:text-gray-300 hover:text-black dark:hover:text-white"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </motion.div>
        </div>
      </div>

      {/* Mobile menu */}
      <motion.div
        initial={false}
        animate={{
          height: isOpen ? 'auto' : 0,
          opacity: isOpen ? 1 : 0
        }}
        transition={{ duration: 0.3 }}
        className="md:hidden overflow-hidden bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm"
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          {navItems.map((item) => (
            <React.Fragment key={item.path}>
              <Link
                to={item.path}
                onClick={() => handleNavigation(item.path)}
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100/90 dark:hover:bg-gray-700/90"
              >
                {item.label}
              </Link>
              {item.children?.map((child) => (
                <Link
                  key={child.path}
                  to={child.path}
                  onClick={() => handleNavigation(child.path)}
                  className="block px-3 py-2 pl-6 rounded-md text-sm font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-100/90 dark:hover:bg-gray-700/90"
                >
                  {child.label}
                </Link>
              ))}
            </React.Fragment>
          ))}
        </div>
      </motion.div>
    </nav>
  );
};

export default Navbar;