import React from 'react';
import { motion } from 'framer-motion';
import { Scale } from 'lucide-react';

const LegalNotice = () => {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 pt-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <Scale size={48} className="mx-auto mb-6 text-gray-900 dark:text-white" />
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Aviso Legal
          </h1>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="prose dark:prose-invert max-w-none"
        >
          <div className="space-y-6 text-gray-600 dark:text-gray-300">
            <section>
              <p>La utilización de este sitio web así como de cualquiera de sus servicios implica la lectura, comprensión y aceptación del presente aviso legal por parte del usuario. En cumplimiento con lo establecido en la Ley 34/2002 de 11 de Julio, de Servicios de la Sociedad de la Información y el Comercio Electrónico (LSSICE), se informa de los siguientes aspectos legales:</p>
              <p><strong>Responsable del sitio web:</strong> S Gramage Hogar, S.L. (SG Hogar)</p>
              <p><strong>Dirección:</strong> Avenida del Textil 63, 46870 Ontinyent Valencia (España)</p>
              <p><strong>Datos fiscales:</strong> B53965489</p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mt-8 mb-4">Información relevante</h2>
              <p>S Gramage Hogar S.L.</p>
              <p>Avda. Textil,63</p>
              <p>46870 Ontinyent (Valencia)</p>
              <p>Tel. 96 650 01 70</p>
              <p>B53965489</p>
              <p>info@sgramagehogar.es</p>
              <p>Inscrita en el Registro Mercantil de Valencia Tomo 9844 Folio 184 Hoja V-161262</p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mt-8 mb-4">Condiciones previas</h2>
              <p>Las presentes condiciones legales regulan el uso legal permitido de este sitio web, cuyo responsable legal es SG Hogar. Estas condiciones generales regulan el acceso y utilización que el titular de la web pone a disposición de los usuarios de Internet. El acceso a la misma implica su aceptación sin reservas. Se entiende por usuario la persona que acceda, navegue, utilice o participe en los servicios y/o actividades de la Web. El mero uso de la web, así como solicitar información o hacer un pedido supone la aceptación plena de las presentes condiciones. Si usted no está de acuerdo con todas las condiciones no debe hacer ningún pedido, ya que al realizar un pedido queda vinculado por estas condiciones.</p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mt-8 mb-4">Uso del sitio web</h2>
              <p>El usuario se obliga a usar el sitio web, los servicios y los contenidos del mismo, de conformidad con la Ley, el presente Aviso Legal, las buenas costumbres y el orden público. Del mismo modo, el usuario se obliga a usarlos de forma diligente, correcta y lícita y no contrarios al contenido del presente Aviso Legal. En particular, se compromete a abstenerse de suprimir, eludir o manipular el "copyright" y demás datos identificativos de los derechos de sus titulares incorporados a los contenidos, así como los dispositivos técnicos de protección, o cualesquiera mecanismos de información que pudieren contener.</p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mt-8 mb-4">Legislación aplicable y jurisdicción</h2>
              <p>Las transacciones comerciales realizadas con S. Gramage Hogal S.L. a través de esta tienda on.line www.sghogar.es están sometidas a la legislación española.</p>
              <p>Leyes de aplicación a la materia:</p>
              <ul className="list-disc pl-6 mt-2">
                <li>Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico.</li>
                <li>Ley 7/96, de 15 de Enero, de Ordenación del Comercio minorista.</li>
              </ul>
              <p className="mt-4">En caso de conflicto o desacuerdo en la interpretación o aplicación de estas Condiciones Generales de Contratación, los Juzgados o Tribunales que conozcan del asunto serán los que disponga la normativa aplicable en materia de jurisdicción. Puede dirigirse a la plataforma de resolución de litigios en línea (ODR) pinchando <a href="https://ec.europa.eu/consumers/odr" className="text-indigo-600 dark:text-indigo-400 hover:underline" target="_blank" rel="noopener noreferrer">AQUÍ</a></p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mt-8 mb-4">Sistema de resolución de reclamaciones</h2>
              <p>"Nuestra entidad se encuentra adherida a Confianza Online (Asociación sin ánimo de lucro), inscrita en el Registro Nacional de Asociaciones Grupo 1, Sección 1, número nacional 594400, CIF G85804011, Calle Castelló 24, Esc 1 2° izq., 28001 Madrid (España), teléfono (+34) 91 309 13 47 y fax (+34) 91 402 83 39 (www.confianzaonline.es).</p>
              <p className="mt-4">Estas Condiciones Generales se rigen por la ley española. Así mismo, en cumplimiento de lo dispuesto en la Ley de Resolución Alternativa de Litigios, informamos a los consumidores que, como entidad adherida y en los términos del Código Ético, los usuarios podrán acudir a Confianza Online para la resolución alternativa de eventuales controversias (<a href="https://www.confianzaonline.es/consumidores/como-reclamar/formulario-reclamaciones/" className="text-indigo-600 dark:text-indigo-400 hover:underline" target="_blank" rel="noopener noreferrer">https://www.confianzaonline.es/consumidores/como-reclamar/formulario-reclamaciones/</a>). Si éstas se refieren a transacciones electrónicas con consumidores, o sobre protección de datos cuando estén relacionadas con este ámbito, las reclamaciones serán resueltas por el Comité de Mediación de Confianza Online, acreditado para la resolución alternativa de litigios en materia de consumo. Si las reclamaciones versan sobre publicidad digital, o sobre protección de datos relacionada con este ámbito, serán sometidas al Jurado de la Publicidad de AUTOCONTROL. Además te recordamos que puedes acceder a la plataforma de resolución de litigios en línea de la Unión Europea siguiendo este enlace: <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show" className="text-indigo-600 dark:text-indigo-400 hover:underline" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/main/?event=main.home2.show</a>"</p>
            </section>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LegalNotice;