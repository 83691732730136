import { useState, useEffect, useRef } from 'react';
import { setupVideoElement, playVideo, cleanupVideo, preloadVideos } from '../utils/videoHelpers';

interface VideoRotationConfig {
  videos: string[];
  totalDuration: number;
}

export const useVideoRotation = ({ videos, totalDuration }: VideoRotationConfig) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>(new Array(videos.length).fill(null));
  const rotationTimer = useRef<NodeJS.Timeout>();

  const durationPerVideo = totalDuration / videos.length; // Duration per video
  const transitionDuration = 0.5;

  const initializeVideo = async (video: HTMLVideoElement | null, src: string) => {
    if (!video) return;
    setupVideoElement(video);
    video.src = src;
    await video.load();
  };

  const rotateToNextVideo = async () => {
    const nextIndex = (currentIndex + 1) % videos.length;
    const currentVideo = videoRefs.current[currentIndex];
    const nextVideo = videoRefs.current[nextIndex];

    if (currentVideo && nextVideo) {
      setIsTransitioning(true);

      // Prepare next video
      await initializeVideo(nextVideo, videos[nextIndex]);
      await playVideo(nextVideo);

      // Switch videos
      setTimeout(() => {
        setCurrentIndex(nextIndex);
        setIsTransitioning(false);
      }, transitionDuration * 1000);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      // Preload all videos
      await preloadVideos(videos);

      // Initialize first video
      const firstVideo = videoRefs.current[0];
      if (firstVideo) {
        await initializeVideo(firstVideo, videos[0]);
        await playVideo(firstVideo);
      }

      setIsInitialized(true);

      // Start rotation
      rotationTimer.current = setInterval(rotateToNextVideo, durationPerVideo);
    };

    initialize();

    return () => {
      if (rotationTimer.current) {
        clearInterval(rotationTimer.current);
      }

      // Cleanup videos
      videoRefs.current.forEach(video => {
        if (video) cleanupVideo(video);
      });
    };
  }, []);

  return {
    currentIndex,
    nextIndex: (currentIndex + 1) % videos.length,
    isTransitioning,
    isInitialized,
    videoRefs,
    transitionDuration
  };
};