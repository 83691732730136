import React from 'react';
import { motion } from 'framer-motion';
import { Shield } from 'lucide-react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 pt-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <Shield size={48} className="mx-auto mb-6 text-gray-900 dark:text-white" />
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Política de Privacidad
          </h1>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="prose dark:prose-invert max-w-none"
        >
          <div className="space-y-6 text-gray-600 dark:text-gray-300">
            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Confidencialidad y protección de datos</h2>
              <p>A efectos de lo previsto en los Artículos 13 y 14 del REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos: RGPD), S. GRAMAGE HOGAR S.L., con CIF B-53965489 y con domicilio fiscal en Avda. Textil 63, 46870 Ontinyent (Valencia), en adelante SG Hogar, informa al usuario de la existencia de un fichero automatizado de datos de carácter personal creado por y para S. GRAMAGE HOGAR S.L., y bajo su responsabilidad.</p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">¿Qué información recopilamos?</h2>
              <p>Para llevar a cabo correctamente las solicitudes de compra de los usuarios del sitio, se le solicita que proporcione toda o parte de la siguiente información:</p>
              <ul className="list-disc pl-6 mt-2">
                <li>Su nombre</li>
                <li>Su correo electrónico</li>
                <li>Su dirección de correo postal</li>
                <li>Su dirección de entrega</li>
                <li>Su número de teléfono</li>
                <li>Su CIF/NIF en caso de requerir factura</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Finalidad del tratamiento</h2>
              <ul className="list-disc pl-6 mt-2">
                <li>Posibilitar la comunicación entre la empresa y el cliente</li>
                <li>Procesar tu pedido</li>
                <li>Contacto mediante mail o telefónico en caso de incidencias</li>
                <li>Permitir la respuesta a su consulta</li>
                <li>Procesar la forma de pago</li>
                <li>Enviar ofertas y promociones</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Derechos del usuario</h2>
              <p>Tiene derecho a solicitar información sobre sus datos que procesamos o almacenamos. Puede ejercer los derechos de acceso, rectificación, cancelación y oposición dirigiéndose a:</p>
              <p className="mt-2">
                <strong>Dirección postal:</strong> Avda. Textil 63, 46870 Ontinyent (Valencia)<br />
                <strong>Correo electrónico:</strong> info@sgramagehogar.es
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Contacto</h2>
              <p>Para cualquier duda o consulta, puede contactarnos:</p>
              <ul className="list-disc pl-6 mt-2">
                <li>Teléfono: 672 643 999 (8:00h a 15:00h)</li>
                <li>Email: info@sgramagehogar.es</li>
                <li>Dirección: Avda. Textil 63, 46870 Ontinyent (Valencia)</li>
              </ul>
            </section>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;