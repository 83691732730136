import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Sofa, Bath, TreePine, UtensilsCrossed } from 'lucide-react';

const services = [
  {
    title: 'Salón',
    description: 'Decoración textil para salas que transforman espacios en hogares acogedores.',
    icon: Sofa,
    path: '/servicios/salon'
  },
  {
    title: 'Baño',
    description: 'Textiles de alta calidad para baños que combinan funcionalidad y elegancia.',
    icon: Bath,
    path: '/servicios/bano'
  },
  {
    title: 'Jardín',
    description: 'Soluciones textiles para exteriores que resisten las inclemencias del tiempo.',
    icon: TreePine,
    path: '/servicios/jardin'
  },
  {
    title: 'Cocina',
    description: 'Textiles funcionales y decorativos para el corazón de tu hogar.',
    icon: UtensilsCrossed,
    path: '/servicios/cocina'
  }
];

const ServicesOverview = () => {
  const navigate = useNavigate();

  const handleServiceClick = (path: string) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };

  return (
    <div className="py-20 bg-white dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Nuestros Servicios
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Descubre nuestra gama completa de soluciones textiles
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.path}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
            >
              <button
                onClick={() => handleServiceClick(service.path)}
                className="w-full group block relative overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 bg-gray-100 dark:bg-gray-800 p-8"
              >
                <div className="flex flex-col items-center text-center">
                  <service.icon size={48} className="mb-4 text-gray-900 dark:text-white" />
                  <h2 className="text-2xl font-bold mb-2 text-gray-900 dark:text-white">{service.title}</h2>
                  <p className="text-gray-600 dark:text-gray-300">
                    {service.description}
                  </p>
                </div>
              </button>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesOverview;