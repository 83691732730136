import React from 'react';
import { motion } from 'framer-motion';
import { MapPin, Phone, Mail } from 'lucide-react';

const Contact = () => {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 pt-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Contacto
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Estamos aquí para ayudarte
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="space-y-8"
          >
            <div className="flex items-start space-x-4">
              <MapPin size={24} className="text-gray-900 dark:text-white flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Dirección
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  Av. del Textil, 63<br />
                  46870 Ontinyent<br />
                  València, España
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <Phone size={24} className="text-gray-900 dark:text-white flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Teléfono
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  <a href="tel:+34672643999">+34 672 64 39 99</a>
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <Mail size={24} className="text-gray-900 dark:text-white flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Correo electrónico
                </h3>
                <p className="text-gray-600 dark:text-gray-300">
                  <a href="mailto:customer@sgpartners.es">customer@sgpartners.es</a>
                </p>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="h-96 rounded-lg overflow-hidden shadow-lg"
          >
            <iframe
              title="Ubicación de GramageTextil"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3117.5776356466387!2d-0.6083799!3d38.8206492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd61cb1e88888889%3A0x9bd8f88887777777!2sAv.%20del%20T%C3%A8xtil%2C%2063%2C%2046870%20Ontinyent%2C%20Val%C3%A8ncia!5e0!3m2!1ses!2ses!4v1234567890"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Contact;