import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send, FileText } from 'lucide-react';

const Project = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    projectType: '',
    description: '',
    budget: '',
    timeline: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const subject = `Nuevo Proyecto - ${formData.projectType}`;
    const body = `
Nombre: ${formData.name}
Email: ${formData.email}
Teléfono: ${formData.phone}
Tipo de Proyecto: ${formData.projectType}
Descripción: ${formData.description}
Presupuesto: ${formData.budget}
Plazo deseado: ${formData.timeline}
    `.trim();

    const mailtoLink = `mailto:customer@sgpartners.es?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 pt-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <FileText size={48} className="mx-auto mb-6 text-indigo-600 dark:text-indigo-400" />
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Cuéntanos tu Proyecto
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Comparte los detalles de tu proyecto y nos pondremos en contacto contigo
          </p>
        </motion.div>

        <motion.form
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          onSubmit={handleSubmit}
          className="space-y-6"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Nombre completo
              </label>
              <motion.input
                type="text"
                name="name"
                id="name"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all"
                onChange={handleChange}
                value={formData.name}
                whileFocus={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Correo electrónico
              </label>
              <motion.input
                type="email"
                name="email"
                id="email"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all"
                onChange={handleChange}
                value={formData.email}
                whileFocus={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Teléfono
              </label>
              <motion.input
                type="tel"
                name="phone"
                id="phone"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all"
                onChange={handleChange}
                value={formData.phone}
                whileFocus={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </div>

            <div>
              <label htmlFor="projectType" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Tipo de proyecto
              </label>
              <motion.select
                name="projectType"
                id="projectType"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all"
                onChange={handleChange}
                value={formData.projectType}
                whileFocus={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <option value="">Selecciona una opción</option>
                <option value="salon">Salón</option>
                <option value="bano">Baño</option>
                <option value="jardin">Jardín</option>
                <option value="cocina">Cocina</option>
                <option value="otro">Otro</option>
              </motion.select>
            </div>
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Descripción del proyecto
            </label>
            <motion.textarea
              name="description"
              id="description"
              rows={4}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all"
              onChange={handleChange}
              value={formData.description}
              whileFocus={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            ></motion.textarea>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="budget" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Presupuesto estimado
              </label>
              <motion.input
                type="text"
                name="budget"
                id="budget"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all"
                onChange={handleChange}
                value={formData.budget}
                whileFocus={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </div>

            <div>
              <label htmlFor="timeline" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Plazo deseado
              </label>
              <motion.input
                type="text"
                name="timeline"
                id="timeline"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white transition-all"
                onChange={handleChange}
                value={formData.timeline}
                whileFocus={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </div>
          </div>

          <motion.button
            type="submit"
            className="w-full flex justify-center items-center px-4 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-transform transform hover:scale-105"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <Send size={20} className="mr-2" />
            Enviar Proyecto
          </motion.button>
        </motion.form>
      </div>
    </div>
  );
};

export default Project;