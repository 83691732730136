import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const videos = [
  'https://cdn.shopify.com/videos/c/o/v/d2268a2602c8426ca05e18c1e89b0c18.mp4',
  'https://cdn.shopify.com/videos/c/o/v/3437251d8cfb461b81f43bf7dec16437.mp4',
  'https://cdn.shopify.com/videos/c/o/v/a1c3e0cc06ae45168af2cca702b76be2.mp4'
];

const Hero = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(0);
      setTimeout(() => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
        setOpacity(1);
      }, 500);
    }, 8333); // 25 seconds total / 3 videos ≈ 8.333 seconds per video

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative h-screen">
      <div className="absolute inset-0">
        <motion.video
          key={currentVideoIndex}
          className="w-full h-full object-cover"
          autoPlay
          playsInline
          muted
          loop
          initial={{ opacity: 0 }}
          animate={{ opacity }}
          transition={{ duration: 0.5 }}
        >
          <source src={videos[currentVideoIndex]} type="video/mp4" />
        </motion.video>
        <div className="absolute inset-0 bg-gradient-to-b from-black/50 to-black/20" />
      </div>

      <div className="relative h-full flex items-center justify-center text-center px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
            GramageTextil
          </h1>
          <p className="text-xl md:text-2xl text-white font-light">
            Innovación en Confección Textil
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;