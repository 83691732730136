import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import DarkModeToggle from './ui/DarkModeToggle';
import { usePasswordProtection } from '../hooks/usePasswordProtection';
import BlockedLayout from './blocked/BlockedLayout';

const Layout = () => {
  const { isValidated } = usePasswordProtection();

  if (!isValidated) {
    return <BlockedLayout />;
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200">
      <Navbar />
      <Outlet />
      <DarkModeToggle />
      <Footer />
    </div>
  );
};

export default Layout;