import React from 'react';
import { motion } from 'framer-motion';
import { useVideoRotation } from '../../hooks/useVideoRotation';

const videos = [
  'https://cdn.shopify.com/videos/c/o/v/d2268a2602c8426ca05e18c1e89b0c18.mp4',
  'https://cdn.shopify.com/videos/c/o/v/3437251d8cfb461b81f43bf7dec16437.mp4',
  'https://cdn.shopify.com/videos/c/o/v/a1c3e0cc06ae45168af2cca702b76be2.mp4'
];

const VideoBackground = () => {
  const {
    currentIndex,
    isTransitioning,
    videoRefs,
    transitionDuration
  } = useVideoRotation({
    videos,
    totalDuration: 30000
  });

  return (
    <div className="absolute inset-0 overflow-hidden">
      {videos.map((src, index) => (
        <motion.video
          key={index}
          ref={el => videoRefs.current[index] = el}
          className="absolute inset-0 w-full h-full object-cover"
          style={{
            opacity: currentIndex === index ? (isTransitioning ? 0 : 0.3) : 0,
            transition: `opacity ${transitionDuration}s ease-in-out`
          }}
          playsInline
          muted
          loop
        >
          <source src={src} type="video/mp4" />
        </motion.video>
      ))}
    </div>
  );
};

export default VideoBackground;