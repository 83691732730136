import { useState, useEffect } from 'react';

const CORRECT_PASSWORD = 'SGhogar_2024';
const PASSWORD_KEY = 'password_validated';

export const usePasswordProtection = () => {
  const [password, setPassword] = useState('');
  const [isValidated, setIsValidated] = useState(() => {
    return localStorage.getItem(PASSWORD_KEY) === 'true';
  });
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);

  const validatePassword = () => {
    if (password === CORRECT_PASSWORD) {
      setIsValidated(true);
      localStorage.setItem(PASSWORD_KEY, 'true');
      setError('');
      setShowError(false);
    } else {
      setError('Contraseña incorrecta');
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    }
    setPassword('');
  };

  return {
    password,
    setPassword,
    isValidated,
    error,
    showError,
    validatePassword
  };
};