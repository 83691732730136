import React from 'react';
import { motion } from 'framer-motion';
import { UtensilsCrossed } from 'lucide-react';

const CocinaService = () => {
  return (
    <div className="py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <UtensilsCrossed size={48} className="mx-auto mb-6 text-gray-900 dark:text-white" />
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Textiles para Cocina
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Funcionalidad y estilo para el corazón del hogar
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img
              src="url"
              alt="Cocina moderna"
              className="rounded-lg shadow-lg"
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="space-y-6"
          >
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              Nuestras Soluciones
            </h2>
            <ul className="space-y-4 text-gray-600 dark:text-gray-300">
              <li>• Manteles y servilletas</li>
              <li>• Delantales personalizados</li>
              <li>• Paños de cocina absorbentes</li>
              <li>• Fundas para electrodomésticos</li>
              <li>• Cortinas y estores</li>
            </ul>

            <div className="pt-6">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                Calidad Superior
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Nuestros textiles de cocina están diseñados para soportar el uso diario
                intensivo. Fabricados con materiales de alta calidad, son fáciles de limpiar
                y mantienen su aspecto impecable lavado tras lavado.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default CocinaService;