import React from 'react';
import { motion } from 'framer-motion';
import { TreePine } from 'lucide-react';

const JardinService = () => {
  return (
    <div className="py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <TreePine size={48} className="mx-auto mb-6 text-gray-900 dark:text-white" />
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Textiles para Jardín
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Soluciones duraderas para espacios exteriores
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img
              src="url"
              alt="Jardín con textiles"
              className="rounded-lg shadow-lg"
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="space-y-6"
          >
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              Nuestras Soluciones
            </h2>
            <ul className="space-y-4 text-gray-600 dark:text-gray-300">
              <li>• Cojines resistentes al agua</li>
              <li>• Toldos y parasoles</li>
              <li>• Fundas para muebles de exterior</li>
              <li>• Alfombras de exterior</li>
              <li>• Textiles decorativos resistentes a UV</li>
            </ul>

            <div className="pt-6">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                Tecnología Avanzada
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Utilizamos tejidos especialmente tratados para resistir las inclemencias del
                tiempo, los rayos UV y el moho. Nuestros textiles para exterior mantienen su
                color y durabilidad incluso en las condiciones más exigentes.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default JardinService;