import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
}

const SEO: React.FC<SEOProps> = ({
  title = 'GramageTextil - Innovación en Confección Textil',
  description = 'Facilitamos y acompañamos la creación de proyectos textiles. Especialistas en textiles para salón, baño, jardín y cocina. Innovación y calidad en confección textil.',
  keywords = 'textil, confección, tejidos, tapicería, cortinas, decoración, hogar, ontinyent, valencia',
  image = 'https://cdn.shopify.com/s/files/1/0692/1132/4730/files/LOGO_GRAMAGE_TEXTIL.png'
}) => {
  const location = useLocation();
  const canonicalUrl = `https://gramagetextil.com${location.pathname}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={canonicalUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />

      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl} />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "GramageTextil",
          "description": description,
          "url": "https://gramagetextil.com",
          "logo": image,
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+34-672-643-999",
            "contactType": "customer service",
            "areaServed": "ES",
            "availableLanguage": ["Spanish", "English", "French", "Catalan"]
          },
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Avenida del Textil 63",
            "addressLocality": "Ontinyent",
            "postalCode": "46870",
            "addressRegion": "Valencia",
            "addressCountry": "ES"
          },
          "sameAs": [
            "https://www.facebook.com/gramagetextil",
            "https://www.instagram.com/gramagetextil",
            "https://www.linkedin.com/company/gramagetextil"
          ]
        })}
      </script>
    </Helmet>
  );
};

export default SEO;